<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t("Show") }}</label>
              <label
                class="text-muted ml-2 mr-2"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</label>
              <label for="">{{ $t("entries") }}</label>

              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="select-size-sm"
                @input="pageSize"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label>{{ $t("Product") }}</label>
              <b-row>
                <b-col cols="11">
                  <v-select
                    v-model="productId"
                    size="sm"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="respDataSelection"
                    :reduce="(respDataSelection) => respDataSelection._id"
                    :clearable="true"
                    class="select-size-sm"
                    @search="onSearchUser"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageSelection <= 1 ? true : false"
                            @click="nextPageUser(currentPageSelection - 1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >
                            {{ currentPageSelection }}
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="
                              currentPageSelection * perPageSelection >=
                                queriedItemsSelection
                                ? true
                                : false
                            "
                            @click="nextPageUser(currentPageSelection + 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </v-select>
                </b-col>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  size="sm"
                  @click="addListProduct()"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-flex"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"

        >
          <template #cell(name)="data">
            <p>
              {{
                data.item.optionNameTranslate === "multiple"
                  ? showFromCurrentLanguage(data.item.nameTranslate)
                  : data.item.name
              }}
            </p>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/promotion'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'promotion'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      productId: '',
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageSelection: 1,
      searchQuerySelection: '',
      perPageSelection: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'name', label: this.$t('Name'), sortable: false },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respDataProduct != null
        ? store.state[STORE_MODULE_NAME].respDataProduct.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respDataProduct != null
        ? store.state[STORE_MODULE_NAME].respDataProduct.max
        : 0
    },
    respDataSelection() {
      return store.state[STORE_MODULE_NAME].respDataSelectionProduct != null
        ? store.state[STORE_MODULE_NAME].respDataSelectionProduct.data.map(e => ({
          _id: e._id,
          name: e.optionNameTranslate === 'multiple' ? this.showFromCurrentLanguage(e.nameTranslate) : e.name,
        }))
        : []
    },
    queriedItemsSelection() {
      return store.state[STORE_MODULE_NAME].respDataSelectionProduct != null
        ? store.state[STORE_MODULE_NAME].respDataSelectionProduct.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, storeModule) }
    this.init()
    this.getSelection()
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    addListProduct() {
      if (this.productId) {
        const obj = {
          id: this.$route.params.id,
          productId: this.productId,
        }
        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/addListProduct`, obj)
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.show = false
            this.productId = ''
            this.getSelection();
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            // eslint-disable-next-line no-unused-vars
          })
          .catch(error => {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    onSearchUser(query) {
      this.searchQuerySelection = query
      this.currentPageSelection = 1
      this.getSelection()
    },
    getSelection() {
      const obj = {
        id: this.$route.params.id,
        currentPage: this.currentPageSelection,
        pageSize: this.perPageSelection,
        searchQuery: this.searchQuerySelection,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/getSelectionProduct`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    init() {
      const obj = {
        id: this.$route.params.id,
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/getListProduct`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      this.init()
    },
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/deleteListProduct`, {
                id: this.$route.params.id,
                productId: id,
              })
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      this.init()
    },
    nextPage(page) {
      this.currentPage = page
      this.init()
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
